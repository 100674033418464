<template>
    <lassoLayout>
        <div class="page_form_layout full_page">
            <v-container class="full_page">
                <form action="#" @submit.prevent="goForgotPassword">
                    <div class="wrap_form_layout account_recovery">
                        <h2 class="account_recovery">
                            Account recovery
                            <span class="hint">Request a password reset below</span>
                        </h2>
                        <template v-if="!submitted">
                            <div class="form">
                                <div class="email">
                                    <input autofocus type="text" v-model="resetPasswordRequest.email" autocomplete="off" placeholder="Enter your email"
                                           @blur="$v.resetPasswordRequest.email.$touch()" required>
	                                <div class="error-msg" v-if="!$v.resetPasswordRequest.email.$error && !$v.resetPasswordRequest.email.maxLength">
		                                Email must be 128 characters or less
	                                </div>
                                </div>
                            </div>
	                        <div class="message_error" v-if="$v.resetPasswordRequest.$error">Please enter a valid email address</div>
                            <div v-if="message" class="message_error">{{ message }}</div>

	                        <button type="submit" class="primary_btn reset" v-ripple :class="{'loading': loader}">
		                        Reset
		                        <v-progress-circular :width="2" indeterminate></v-progress-circular>
	                        </button>
<!--                            <button v-if="!$v.resetPasswordRequest.email.$invalid" type="submit" class="primary_btn reset" v-ripple :class="{'loading': loader}">-->
<!--                                Reset-->
<!--                                <v-progress-circular :width="2" indeterminate></v-progress-circular>-->
<!--                            </button>-->
<!--                            <button v-if="$v.resetPasswordRequest.email.$invalid" type="button" class="primary_btn reset" disabled v-ripple>Reset</button>-->
                            <router-link :to="{name: 'login'}" class="cancel_account_recovery">Cancel</router-link>
                        </template>
                        <template v-if="submitted">
                            <div class="response_account_recovery">
                                <div class="account_recovery_message">Check your email (including spam) for reset instructions.</div>
                                <span>No email?</span>
                                <button type="button" class="primary_btn small try_again" @click="tryAgain()" :disabled="timer > 0">
                                    Try again
                                    <template v-if="timer > 0">
                                        in {{timer}} seconds
                                    </template>
                                </button>
                                <router-link :to="{name: 'login'}" class="login">Log In</router-link>
                            </div>
                        </template>
                    </div>
                </form>

            </v-container>
        </div>
    </lassoLayout>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {email, required, maxLength} from 'vuelidate/lib/validators';
import lassoLayout from "@/layouts/lassoLayout.vue";

export default {
    name: 'forgot-password',
    components: {
        lassoLayout
    },
    data() {
        return {
            resetPasswordRequest: {
                email: ''
            },
            submitted: false,
            message: '',
            loader: false,
            timer: 60
        }
    },
    watch: {
		'resetPasswordRequest.email'() {
			this.$v.$reset();
		}
    },
    validations: {
        resetPasswordRequest: {
            email: {
				email,
	            required,
	            maxLength: maxLength(128)
            }
        }
    },
    computed: {
        ...mapGetters(['FORGOT_PASSWORD_STATUS']),
    },
    methods: {
        ...mapActions(['FORGOT_PASSWORD']),

        countDownTimer() {
            if(this.timer > 0) {
                setTimeout(() => {
                    this.timer -= 1;
                    this.countDownTimer();
                }, 1000);
            }
        },
        goForgotPassword() {
			this.$v.resetPasswordRequest.email.$touch();

			if (this.$v.resetPasswordRequest.email.$error === false) {
				this.loader = true;
				this.FORGOT_PASSWORD(this.resetPasswordRequest)
					.then(() => {
						if (this.FORGOT_PASSWORD_STATUS === 'success') {
							this.submitted = true;
							this.countDownTimer();
						} else {
							this.message = 'Failed to account recovery';
							this.submitted = false;
						}
					})
					.catch(err => {
						console.error(`goForgotPassword ${err}`);
						this.message = 'Failed to account recovery';
						this.submitted = false;
					})
					.finally(() => {
						this.loader = false;
					})
			}
        },
        tryAgain() {
            this.timer = 60;
            this.submitted = false;
            this.loader = false;
        }
    }
}


</script>

<style lang="scss">
.response_account_recovery {
    .account_recovery_message {
        font-size: 16px;
        color: #E94E4E;
        text-align: center;
        white-space: nowrap;
    }
}
</style>
